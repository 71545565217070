/** Auth Form Styles */
export const hideElement = { display: 'none' };

export const showElement = { display: 'block' };

export const getSpinnerStyle = (loading) => {
	if (loading) {
		return {
			...showElement,
			color: 'white'
		}
	}
	else {
		return hideElement;
	}
}