import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfoNugget from '../../../components/InfoNugget/InfoNugget';

const DashboardView = () => {
	const navigate = useNavigate();
	const listings = useSelector((state) => state.listings.data);
	const payouts = useSelector((state) => state.payouts.data);
	const unverifiedListings = useSelector((state) => state.listings.data.filter((listing) => listing.status === 'UNVERIFIED'))

	const handleClickListingsNugget = () => {
		navigate(`/?view=listings`);
	}

	const handleClickPayoutNugget = () => {
		navigate(`/?view=payout`);
	}

	return (
		<div className='info-container'>
			<InfoNugget
				theme='coral'
				icon='listings'
				label='# of Listings'
				value={listings.length}
				onClick={handleClickListingsNugget}
			/>
			<InfoNugget
				theme='blue'
				icon='listings'
				label='# of Unverified Listings'
				value={unverifiedListings.length}
				onClick={handleClickListingsNugget}
			/>
			<InfoNugget
				theme='gold'
				icon='payout'
				label='# of Pending Payout'
				value={payouts.length}
				onClick={handleClickPayoutNugget}
			/>
		</div >
	)
}

export default DashboardView;