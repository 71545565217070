import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import initFirebaseApp from '../../firebaseConfig';
import AuthForm from '../../components/AuthForm';

const auth = getAuth(initFirebaseApp());

const Login = () => {
	const [user] = useAuthState(auth);
	return (
		<main className='bg-secondary'>
			{user && (
				<Navigate to='/?view=dashboard' replace={true} />
			)}
			<section className='bg-circles auth-form-container'>
				<AuthForm
					type='login'
				/>
			</section>
		</main>
	);
}

export default Login;
