import {
	Routes as AppRoutes,
	Route,
} from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/auth/Login';


const Routes = () => {
	return (
		<AppRoutes>
			<Route path='/' element={<Home />} />
			<Route path='login' element={<Login />} />
		</AppRoutes >
	);
}

export default Routes;