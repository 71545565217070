import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import initFirebaseApp from '../../firebaseConfig';
import { getSpinnerStyle, hideElement, showElement } from './utils';
import './styles.css';

const styles = () => ({
	root: {
		color: '#354052',
	},
	underlineLogin: {
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: '1px solid #923B96 !important',
		},
		'&:after': {
			borderBottom: '2px solid #923B96',
		},
	},
	underlineSignup: {
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: '1px solid #F7BF23 !important',
		},
		'&:after': {
			borderBottom: '2px solid #F7BF23',
		},
	},
	disabled: {},
	focused: {},
	error: {},
});

const auth = getAuth(initFirebaseApp());

const AuthForm = ({ classes = {} }) => {
	const [emailField, setEmailField] = useState('');
	const [passwordField, setPasswordField] = useState('');
	const [emailFieldError, setEmailFieldError] = useState('');
	const [passwordFieldError/* setPasswordFieldError*/] = useState('');

	const [loading, setLoading] = useState(false);

	const inputStyle = {
		root: classes.root,
		input: classes.inputLogin,
		underline: classes.underlineLogin
	}

	const renderEmailField = () => {
		return (
			<div>
				<TextField
					id='your-uniqueness'
					label='Email Address'
					type='email'
					placeholder='Enter email'
					style={{ marginBottom: 12 }}
					error={!!emailFieldError}
					aria-describedby='error-email-field'
					fullWidth
					required
					InputProps={{
						classes: inputStyle
					}}
					value={emailField}
					onChange={(e) => {
						setEmailField(e.target.value);
					}}
				/>
				<span id='error-email-field' role='alert' className='error'>{emailFieldError}</span>
			</div>
		);
	};

	const renderPasswordField = () => {
		return (
			<div>
				<TextField
					label="Password"
					type="password"
					placeholder="Enter password here"
					style={{ marginBottom: 12 }}
					error={!!passwordFieldError}
					aria-describedby='error-password-field'
					fullWidth
					required
					InputProps={{
						classes: inputStyle
					}}
					value={passwordField}
					onChange={(e) => {
						setPasswordField(e.target.value);
					}}
				/>
				<span id='error-password-field' role='alert' className='error'>{passwordFieldError}</span>
			</div>
		);
	}

	const handleLogin = async () => {
		// TODO: Do some validation here.

		setLoading(true);
		try {
			signInWithEmailAndPassword(auth, emailField, passwordField)
				.then((userCredential) => {
					const user = userCredential.user;
					setLoading(false);
					console.log(user)
				})
				.catch((error) => {
					// const errorCode = error.code;
					const errorMessage = error.message;
					setLoading(false);
					setEmailFieldError(errorMessage);
				});
		}
		catch (e) { }
	}

	const renderAuthForm = () => {
		return (
			<div className='flex-column-center authform-container'>
				<h2 className='form-header'>
					Please login to continue
				</h2>

				{renderEmailField()}
				{renderPasswordField()}

				<div className='login-btn-container'>
					<button type='button'
						className={`login-btn ${loading ? 'loading' : ''}`}
						onClick={handleLogin}
						disabled={loading ? true : false}>
						<CircularProgress size={32}
							style={getSpinnerStyle(loading)}
						/>
						<span style={loading ? hideElement : showElement}>
							Login
						</span>
					</button>
				</div>

			</div>
		)
	}

	return (
		<form className='authform-root'>
			{renderAuthForm()}
		</form>
	);
}

export default withStyles(styles)(AuthForm);