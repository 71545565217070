import { fetchPendingPayouts, updatePayout } from '../../../../services/ApiClient';

export const GET_ALL_PENDING_PAYOUTS_LOADING = 'GET_ALL_PENDING_PAYOUTS_LOADING';
export const GET_ALL_PENDING_PAYOUTS_REQUEST = 'GET_ALL_PENDING_PAYOUTS_REQUEST';
export const GET_ALL_PENDING_PAYOUTS_SUCCESS = 'GET_ALL_PENDING_PAYOUTS_SUCCESS';
export const TRIGGER_PAYOUT_LOADING = 'TRIGGER_PAYOUT_LOADING';
export const TRIGGER_PAYOUT_SUCCESS = 'TRIGGER_PAYOUT_SUCCESS';

export const getAllPendingPayouts = () => {
	return async (dispatch) => {
		dispatch({
			type: GET_ALL_PENDING_PAYOUTS_LOADING
		});
		const { data } = await fetchPendingPayouts();
		dispatch({
			type: GET_ALL_PENDING_PAYOUTS_SUCCESS,
			payload: data
		});
	}
};

export const triggerPayout = (id) => {
	return async (dispatch) => {
		dispatch({
			type: TRIGGER_PAYOUT_LOADING,
			payload: id
		});
		await updatePayout(id, { status: 'PAID' });
		dispatch({
			type: TRIGGER_PAYOUT_SUCCESS,
			payload: id
		});
	}
}