import React from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import initFirebaseApp from '../../firebaseConfig';
import LELogo from '../../static/brand/LELogo.png';
import './styles.css';

const auth = getAuth(initFirebaseApp());


const LoggedOutNav = () => (
	<nav className='navbar-root'>
		<Link to="/">
			<img src={LELogo} height={50} alt='list-and-earn-logo' aria-label='List and Earn Logo' />
		</Link>
	</nav>
);

const LoggedInNav = () => {
	const handleLogout = () => {
		signOut(auth);
	}

	return (
		<nav className='navbar-root'>
			<Link to="/">
				<img src={LELogo} height={50} alt='list-and-earn-logo' aria-label='List and Earn Logo' />
			</Link>
			<button className='logout-btn' onClick={handleLogout}>Logout</button>
		</nav>
	);
};

const Navbar = () => {
	const [user] = useAuthState(auth);
	const renderNav = () => user ? LoggedInNav() : LoggedOutNav()
	return renderNav();
}

export default Navbar;
