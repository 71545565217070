import React from 'react';
import { useSelector } from 'react-redux';
import { Payout } from '../../../components/Payout/Payout';

const PayoutView = () => {
	const payouts = useSelector((state) => state.payouts.data);
	return (
		<div>
			<h3 className='page-header'>Manage Payouts</h3>
			{
				payouts.length === 0
					? 'No pending payouts'
					: payouts.map((listing) => <Payout key={listing._id} {...listing} />)}
		</div>
	)
}

export default PayoutView;