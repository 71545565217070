import { configureStore } from '@reduxjs/toolkit';
import { apiClient } from '../services/ApiClient';
import {
	listingsReducer,
	payoutsReducer
} from '../pages/home/store/reducers';

const store = configureStore({
	reducer: {
		listings: listingsReducer,
		payouts: payoutsReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: apiClient
			}
		})
})

export default store;