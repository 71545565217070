import { fetchListings, updateListing } from '../../../../services/ApiClient';

export const GET_ALL_LISTINGS_LOADING = 'GET_ALL_LISTINGS_LOADING';
export const GET_ALL_LISTINGS_FAILURE = 'GET_ALL_LISTINGS_FAILURE';
export const GET_ALL_LISTINGS_REQUEST = 'GET_ALL_LISTINGS_REQUEST';
export const GET_ALL_LISTINGS_SUCCESS = 'GET_ALL_LISTINGS_SUCCESS';

export const UPDATE_LISTING_STATUS_LOADING = 'UPDATE_LISTING_STATUS_LOADING';
export const UPDATE_LISTING_STATUS_SUCCESS = 'UPDATE_LISTING_STATUS_SUCCESS';

export const getAllListings = () => {
	return async (dispatch) => {
		dispatch({
			type: GET_ALL_LISTINGS_LOADING
		});
		const { data } = await fetchListings();
		dispatch({
			type: GET_ALL_LISTINGS_SUCCESS,
			payload: data
		});
	}
};

export const verifyListing = (id) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_LISTING_STATUS_LOADING,
			payload: id
		});
		await updateListing(id, { status: 'VERIFIED' });
		dispatch({
			type: UPDATE_LISTING_STATUS_SUCCESS,
			payload: id
		});
	}
}