import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { verifyListing } from '../../pages/home/store/actions/listings';
import './styles.css';

export const Listing = ({ _id, location, type, status }) => {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.listings.dataById[_id]);

	const handleVerifyClick = () => {
		dispatch(verifyListing(_id));
	}

	return (
		<div className='listing-item'>
			<div className='listing-location'>{location}</div>
			<div className='listing-type'>{type}</div>
			<div className='listing-actions'>
				{status === 'UNVERIFIED' && <button disabled={loading} onClick={handleVerifyClick} className='listing-verify-btn'>
					<CircularProgress size={20}
						style={loading ? { display: 'block', color: 'white' } : { display: 'none' }}
					/>
					<span className={loading ? 'listing-btn-label-hide' : 'listing-btn-label-show'}>
						Verify
					</span>
				</button>}
				{status === 'VERIFIED' && <div className='listing-verified-label' disabled>Verified</div>}
				{/* <button>Flag</button> */}
			</div>
		</div>
	)
}