import React, { useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { parse } from 'query-string';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Dashboard from '@material-ui/icons/Dashboard';
import Listings from '@material-ui/icons/ViewList';
import Pay from '@material-ui/icons/AttachMoney';
import initFirebaseApp from '../../firebaseConfig';
import DashboardView from './internals/Dashboard';
import ListingsView from './internals/Listings';
import PayoutView from './internals/Payout';
import './styles.css'
import { getAllListings } from './store/actions/listings';
import { getAllPendingPayouts } from './store/actions/payout';

const DASHBOARD = 'dashboard';
const LISTINGS = 'listings';
const PAYOUT = 'payout';

const auth = getAuth(initFirebaseApp());


/** Layouts */
const LeftNav = () => {
	const location = useLocation();
	const navigate = useNavigate();
	let selectedView = parse(location.search)['view'] || 'dashboard'

	const handleSelectNavOption = (path) => {
		navigate(`/?view=${path}`);
	}

	return (
		<nav className='left-nav bg-universal' aria-label='Left Navigation'>
			<div className='icon-button-root' tabIndex={0} role='button'
				onClick={handleSelectNavOption.bind(null, DASHBOARD)}>
				<Dashboard aria-label='Dashboard' alt={DASHBOARD}
					className={`icon-button ${selectedView === DASHBOARD ? 'selected' : ''}`} />
				<p className={`icon-button-label ${selectedView === DASHBOARD ? 'selected' : ''}`}>Dashboard</p>
			</div>

			<div className='icon-button-root' tabIndex={0} role='button'
				onClick={handleSelectNavOption.bind(null, 'listings')}>
				<Listings aria-label='Listings' alt='Manage Listings'
					className={`icon-button ${selectedView === LISTINGS ? 'selected' : ''}`} />
				<p className={`icon-button-label ${selectedView === LISTINGS ? 'selected' : ''}`}>Listings</p>
			</div>

			<div className='icon-button-root' tabIndex={0} role='button'
				onClick={handleSelectNavOption.bind(null, 'payout')}>
				<Pay aria-label='Manage Payout' alt='Manage Payout'
					className={`icon-button ${selectedView === PAYOUT ? 'selected' : ''}`} />
				<p className={`icon-button-label ${selectedView === PAYOUT ? 'selected' : ''}`}>Payout</p>
			</div>
		</nav>
	);

}


const Home = () => {
	const location = useLocation();
	const [user] = useAuthState(auth);
	const dispatch = useDispatch();
	let selectedView = parse(location.search)['view'] || DASHBOARD;

	useEffect(() => {
		dispatch(getAllListings());
		dispatch(getAllPendingPayouts());
	}, [dispatch])

	return (
		<>
			{!user && (
				<Navigate to="/login" replace={true} />
			)}
			<main className='home-main'>
				<LeftNav />
				<div className='main-content bg-lightgray'>
					{/* TODO: Clean this up */}
					{selectedView === DASHBOARD
						? <DashboardView />
						: selectedView === 'listings'
							? <ListingsView />
							: selectedView === 'payout'
								? <PayoutView /> : <></>
					}
				</div>
			</main>
		</>
	)
}

export default Home