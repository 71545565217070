import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { triggerPayout } from '../../pages/home/store/actions/payout';
import './styles.css';

export const Payout = ({ _id, amount, date, status }) => {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.payouts.dataById[_id]);

	const handleTriggerPayout = () => {
		dispatch(triggerPayout(_id));
	}

	const formatDate = (date) => date && new Date(date).toLocaleDateString('en-GB');

	return (
		<div className='payout-item'>
			<div className='payout-amount'>{`N${amount}.00`}</div>
			<div className='payout-date'>{formatDate(date)}</div>
			<div className='payout-actions'>
				{status === 'UNPAID' && <button disabled={loading} onClick={handleTriggerPayout} className='payout-verify-btn'>
					<CircularProgress size={20}
						style={loading ? { display: 'block', color: 'white' } : { display: 'none' }}
					/>
					<span className={loading ? 'payout-btn-label-hide' : 'payout-btn-label-show'}>
						Pay
					</span>
				</button>}
				{status === 'PAID' && <div className='payout-verified-label'>Paid</div>}
			</div>
		</div>
	)
}