import axios from 'axios';
const LISTING_SERVICE_ENDPOINT = process.env.REACT_APP_LISTING_SERVICE_ENDPOINT;
const PAYOUT_SERVICE_ENDPOINT = process.env.REACT_APP_PAYOUT_SERVICE_ENDPOINT;

//TODO: Change to a class

export const apiClient = (endpoint) => {
	// let accessToken = token || generateAccessToken();
	return axios.create({
		baseURL: endpoint
		// headers: {
		// 	Authorization: `Bearer : ${accessToken}`,
		// 	...(userToken && { 'X-HA-USER-TOKEN': userToken })
		// }
	});
};


export const fetchListings = () => {
	return apiClient(LISTING_SERVICE_ENDPOINT)
		.post('/unauthenticated/listings');
}

export const updateListing = (id, options) => {
	return apiClient(LISTING_SERVICE_ENDPOINT)
		.put(`/authenticated/listing/${id}`, options);
}

export const fetchPendingPayouts = () => {
	return apiClient(PAYOUT_SERVICE_ENDPOINT)
		.get('/payouts/pending'); //TODO: Change to just pending
}

export const updatePayout = (id, options) => {
	return apiClient(PAYOUT_SERVICE_ENDPOINT)
		.put(`/payouts/${id}`, options);
}


