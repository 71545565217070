import {
	GET_ALL_PENDING_PAYOUTS_LOADING,
	GET_ALL_PENDING_PAYOUTS_SUCCESS,
	TRIGGER_PAYOUT_LOADING,
	TRIGGER_PAYOUT_SUCCESS
} from '../actions/payout';

export const INITIAL_PAYOUTS_STATE = {
	completed: false,
	loading: false,
	error: false,
	data: [],
	dataById: {}
}


const constructDataById = (data) => {
	const dataById = {};
	data.forEach(({ _id, ...rest }) => {
		dataById[_id] = {
			_id,
			...rest,
			loading: false,
			error: false
		};
	});
	return dataById
}

export const payoutsReducer = (state = INITIAL_PAYOUTS_STATE, { type, payload }) => {
	switch (type) {
		case GET_ALL_PENDING_PAYOUTS_LOADING:
			return {
				...state,
				loading: true
			}

		case GET_ALL_PENDING_PAYOUTS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				data: payload,
				dataById: constructDataById(payload)
			}

		case TRIGGER_PAYOUT_LOADING:
			return {
				...state,
				dataById: {
					...state.dataById,
					[payload]: {
						loading: true,
						error: false
					}
				}
			}

		case TRIGGER_PAYOUT_SUCCESS:
			const selectedPayout = { ...state.data.filter(({ _id }) => _id === payload)[0] };
			selectedPayout.status = 'PAID';

			return {
				...state,
				data: state.data.filter(({ _id }) => _id !== payload).concat([selectedPayout]),
				dataById: {
					...state.dataById,
					[payload]: {
						loading: false,
						error: false
					}
				}
			}

		default:
			return state;
	}
}