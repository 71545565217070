
import React from 'react';
import Listing from '@material-ui/icons/Business';
import Payout from '@material-ui/icons/AttachMoney';
import './styles.css';


const renderIcon = (icon) => {
	const iconStyle = { color: 'white' };

	if (icon === 'listings') {
		return <Listing style={iconStyle} className='info-nugget-icon' />
	}
	if (icon === 'payout') {
		return <Payout style={iconStyle} className='info-nugget-icon' />
	}
	// if (item.type === 'search') {
	// 	return <Trend style={iconStyle} className={'info-nugget-icon'} />
	// }
	// if (item.type === 'location') {
	// 	return <Location style={iconStyle} className={'info-nugget-icon'} />
	// }
	// if (item.type === 'calendar') {
	// 	return <Calendar style={iconStyle} className={'info-nugget-icon'} />
	// }
	// if (item.type === 'people') {
	// 	return <People style={iconStyle} className={'info-nugget-icon'} />
	// }
	// if (item.type === 'maxpay') {
	// 	return <MaxPay style={iconStyle} className={'info-nugget-icon'} />
	// }
	// if (item.type === 'total') {
	// 	return <Money style={iconStyle} className={'info-nugget-icon'} />
	// }
	// if (item.type === 'viewed') {
	// 	return <Viewed style={iconStyle} className={'info-nugget-icon'} />
	// }
	// return <Listing style={{ fontSize: 64, color: 'white' }} />
}

const InfoNugget = ({
	icon,
	label,
	theme,
	value,
	onClick
}) => {

	return (
		<div className='info-nugget-col'
			onClick={onClick}>
			<div className={`info-nugget-icon info-nugget-icon-${theme}`}>
				{renderIcon(icon)}
			</div>
			<div className='info-nugget-text'>
				<p className='info-nugget-label'>{label}</p>
				<p className='info-nugget-value'>{value}</p>
			</div>
		</div>
	);
}

export default InfoNugget;