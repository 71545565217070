import { initializeApp } from 'firebase/app';
const firebaseConfig = {
	apiKey: "AIzaSyCNK0HicdzkMSnXV6jyGAw3-4elHSV5lO8",
	authDomain: "admin-listandearn.firebaseapp.com",
	projectId: "admin-listandearn",
	storageBucket: "admin-listandearn.appspot.com",
	messagingSenderId: "995604218783",
	appId: "1:995604218783:web:40d0e6d797c5728b0eb3aa",
	measurementId: "G-C18D19QW53"
};


let app;

export const initFirebaseApp = () => {
	if (!app) {
		app = initializeApp(firebaseConfig);
	}
	return app;
}

export default initFirebaseApp;