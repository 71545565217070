import {
	GET_ALL_LISTINGS_FAILURE,
	GET_ALL_LISTINGS_LOADING,
	GET_ALL_LISTINGS_SUCCESS,
	UPDATE_LISTING_STATUS_LOADING,
	UPDATE_LISTING_STATUS_SUCCESS
} from '../actions/listings';

export const INITIAL_LISTINGS_STATE = {
	completed: false,
	loading: false,
	error: false,
	data: [],
	dataById: {}
}

const constructDataById = (data) => {
	const dataById = {};
	data.forEach(({ _id, ...rest }) => {
		dataById[_id] = {
			_id,
			...rest,
			loading: false,
			error: false
		};
	});
	return dataById
}

export const listingsReducer = (state = INITIAL_LISTINGS_STATE, { type, payload }) => {
	switch (type) {
		case GET_ALL_LISTINGS_LOADING:
			return {
				...state,
				loading: true
			}

		case GET_ALL_LISTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				data: payload,
				dataById: constructDataById(payload)
			}

		case UPDATE_LISTING_STATUS_LOADING:
			return {
				...state,
				dataById: {
					...state.dataById,
					[payload]: {
						loading: true,
						error: false
					}
				}
			}

		case UPDATE_LISTING_STATUS_SUCCESS:
			const selectedListing = { ...state.data.filter(({ _id }) => _id === payload)[0] };
			selectedListing.status = 'VERIFIED';

			return {
				...state,
				data: state.data.filter(({ _id }) => _id !== payload).concat([selectedListing]),
				dataById: {
					...state.dataById,
					[payload]: {
						loading: false,
						error: false
					}
				}
			}

		default:
			return state;
	}
}